import React from 'react';
import { Link, useParams, useOutletContext } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { RadioItem } from './Radio'
import * as RADIOS from '../data/state';



export default function Genre() {
  const { genreId } = useParams();
  const genre = RADIOS.genre(genreId);

  // const { radios = [] } = useOutletContext();
  // const genreRadios = radios.filter(({info :{ genres = [] } = {} }) => genres.some(genre => genre.href === genreId)).sort();
  console.log('Genre', genreId, genre);
  if (genre) {
    // const { name, radios } = genre;
    return (
      <>
        {/*<header>{genre.name}</header>*/}
        <section className="bg-red-100 flex-col overflow-auto flex"
          style={{
            display: "flex",
            flexDirection: "column",
            flex:"auto",
            overflow: "auto",
          }}>{
          genre.radios.map(radio =>
            <RadioItem key={radio.channel} { ...radio}/>
          )
        }
        </section>
        <Helmet>
          <title>RadioDNS - {genre.name}</title>
          <link rel="icon" href="/favicon.ico" />
        </Helmet>
      </>
    );
  }
}