import React, { useState, useEffect, useMemo, useRef } from 'react';
import AudioControls from './AudioControls';
import * as RADIOS from '../data/state';

import './RadioPlayer.css';

import { ReactComponent as PlayIcon } from '../assets/play.svg';
import { ReactComponent as StopIcon } from '../assets/stop.svg';
import { ReactComponent as PreviousIcon } from '../assets/previous.svg';
import { ReactComponent as NextIcon } from '../assets/next.svg';


const httpsImage = (url) => {
  console.log('httpsImageurl', url);
  if (!url) return;
  const imageUrl = new URL(url);
  if (imageUrl.protocol === 'https:') return url;
  imageUrl.protocol = 'https:';
  return imageUrl.href;
}


const RadioController = ({ radios }) => {
  console.log("RadioPlayer radios", radios);
  // const radiosWithStreams = radios.filter(({info: { streams = [] } = {} }) => streams?.length);
  // console.log("RadioPlayer radiosWithStreams", radiosWithStreams);

  const [trackIndex, setTrackIndex] = useState(0);
  const [trackProgress, setTrackProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);



  // Destructure for conciseness
  const {
    channel,
    name,
    data: {
      text,
      image
    } = {},
    info : {
      streams: [{id:src}],
    } = {},
  } = radios[trackIndex];

  const imageHttps = httpsImage(image);

  console.log("RadioPlayer channel", channel);

  // Refs
  const audioRef = useRef();
  const intervalRef = useRef();
  const isReady = useRef(false);

  // Destructure for conciseness
  // const { duration } = audioRef.current;


  const toPrevTrack = () => {
    if (trackIndex === 0) {
      setTrackIndex(radios.length - 1);
    } else {
      setTrackIndex(trackIndex - 1);
    }
  }

  const toNextTrack = () => {
    if (trackIndex < radios.length - 1) {
      setTrackIndex(trackIndex + 1);
    } else {
      setTrackIndex(0);
    }
  }

  useEffect(() => {
    (async() => {
      console.log('useEffect new song');
      if (isPlaying) await audioRef.current.pause();
      audioRef.current = new Audio(httpsImage(src));
      if (isPlaying) await audioRef.current.play();
      // return () => audioRef.current.pause();
    })();
  }, [src, isPlaying, audioRef]);

  useEffect(() => {
    console.log('isPlaying', isPlaying);
    (async() => {
      if (isPlaying) {
        await audioRef.current.play();
      } else {
        await audioRef.current.pause();
      }      
    })();

  }, [isPlaying, audioRef]);



  useEffect(() => {
    console.log('MediaMetadata mediaSession');
    if ("mediaSession" in navigator) {
      navigator.mediaSession.setActionHandler("play", () => {
        setIsPlaying(true);
      });
      navigator.mediaSession.setActionHandler("pause", () => {
        setIsPlaying(false);
      });
      navigator.mediaSession.setActionHandler("previoustrack", () => toPrevTrack());
      navigator.mediaSession.setActionHandler("nexttrack", () => toNextTrack());
    }
  }, [setIsPlaying, toNextTrack, toPrevTrack]);

  useEffect(() => {
    console.log('artwork MediaMetadata image', imageHttps);
    if (text && "mediaSession" in navigator) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: text,
        artist: text,
        album: name,
        artwork: [
          { src: imageHttps, sizes: '96x96',   type: 'image/png' },
          { src: imageHttps, sizes: '128x128', type: 'image/png' },
          { src: imageHttps, sizes: '192x192', type: 'image/png' },
          { src: imageHttps, sizes: '256x256', type: 'image/png' },
          { src: imageHttps, sizes: '384x384', type: 'image/png' },
          { src: imageHttps, sizes: '512x512', type: 'image/png' },
        ]
      });
    }

  }, [text, name, imageHttps]);

  const hasMultiTracks = radios.length > 1;

  return (
    <div style={{
      backgroundColor:"white",
      flex:"auto",
      display:"flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "4px",
    }}>
      <div style={{
        alignSelf: "flex-start",
      }}>
        {imageHttps && <img
          className=""
          style={{
            width: "100px",
            float: "left",
          }}
          src={imageHttps}
          alt={`track artwork for ${text} by ${name}`}
        />}
        <div style={{
          display:"flex",
          flexDirection: "column",
          height: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 6px",
        }}>
          <h5 style={{margin: "2px", alignSelf: "flex-start"}}>📻 {name}</h5>
          <h4 style={{
            margin: "2px",
            flex: "auto",
            display: "flex",
            alignItems: "center",

          }}>🎵 {text}</h4>
        </div>
      </div>
      <div>
        <button style={{
          visibility: hasMultiTracks ? "visible" : "hidden",
          scale: "50%",
        }}
        onClick={toPrevTrack}>
          <PreviousIcon/>
        </button>
        {isPlaying
          ?
            <button style={{
              scale: "50%",
            }}
              onClick={() => setIsPlaying(false)}>
              <StopIcon/>
            </button>
          :
            <button style={{
              scale: "50%",
            }}
              onClick={() => setIsPlaying(true)}>
              <PlayIcon/>
            </button>
        }
        <button style={{
          visibility: hasMultiTracks ? "visible" : "hidden",
          scale: "50%",
        }}
        onClick={toNextTrack}
        >
          <NextIcon/>
        </button>
      </div>
    </div>
  );


  return (
    <div className="audio-player">
      <div className="track-info">
        {imageHttps && <img
          className="artwork"
          src={imageHttps}
          alt={`track artwork for ${text} by ${name}`}
        />}
        <h4 className="title">{text}</h4>
        <h5 className="artist">{name}</h5>
        <AudioControls
          isPlaying={isPlaying}
          onPrevClick={hasMultiTracks && toPrevTrack}
          onNextClick={hasMultiTracks && toNextTrack}
          onPlayPauseClick={setIsPlaying}
        />
   {/*     <input
          type="range"
          value={trackProgress}
          step="1"
          min="0"
          max={duration ? duration : `${duration}`}
          className="progress"
          // onChange={(e) => onScrub(e.target.value)}
          // onMouseUp={onScrubEnd}
          // onKeyUp={onScrubEnd}
        />*/}
      </div>
    </div>
  );
}

const RadioPlayer = () => {
  // State
  console.log('RadioPlayer', RADIOS.streams.value)
  if (RADIOS.streams.value?.length)
    return <RadioController radios={RADIOS.streams.value}/>

}



export default RadioPlayer;