import React from 'react';
import { RouterProvider, createBrowserRouter, BrowserRouter, Routes, Route } from 'react-router-dom';

import XML from './data/radios.xml' /*with { type: "xml" };*/

import Root from "./routes/root";
import Index from "./routes/index"
import Posts from './components/posts';
import Post from './components/post';
import List from './components/list';
import Radio from './components/Radio';
import Genre from './components/Genre';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <Index/>
      },
      {
        path: '/radio/:radioId',
        element: <Radio/>,
      },
      {
        path: '/genre/:genreId',
        element: <Genre/>,
      },
      {
        path: "/list",
        element: <List/>
      },
      {
        path: "posts",
        element: <Posts />,
      },
    ]
  },

]);

function App() {

  return (
    <RouterProvider router={router} />
  );
}

export default App;