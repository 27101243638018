import { Link, useParams } from 'react-router-dom'
import * as RADIOS from '../data/state';




const NavStatus = () => {
  const { radioId, genreId } = useParams();
  if (radioId) {
    const radio = RADIOS.station(radioId);
    if (radio) {
      console.log('NavStatus Navbar radioId', radioId, radio);
      return (
        <h4>{radio?.name}</h4>
      );
    }
  }
  if (genreId) {
    const genre = RADIOS.genre(genreId);
    if (genre) {
      console.log('NavStatus Navbar genreId', genreId, genre);
      return (
        <h4>{genre?.name}</h4>
      );
    }
  }
  return (
    <h4>RadioDNS</h4>
  );
}

export default function Navbar() {
  console.log('Navbar');
  return (
    <nav style={{height:"60px"}}>
      <header style={{display:"flex", alignItems:"center"}}>
        <Link style={{padding:"4px"}} to="/">📻</Link>
        <NavStatus/>
      </header>
    </nav>
  );
}
