import React from 'react';

export const httpsImage = (url) => {
  const imageUrl = new URL(url);
  if (imageUrl.protocol === 'https:') return url;
  imageUrl.protocol = 'https:';
  return imageUrl.href;
}

export function Image({ src, style }) {
  // const imageUrl = React.useMemo(() => httpsImage(src), [src]);
  return (
    <img
        style={style}
        src={httpsImage(src)}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = src;
        }}
    />
  );
}