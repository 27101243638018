import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import { parseXmlRadios } from '../lib/parser'
import * as RADIOS from '../data/state';
import RadioGenres from '../data/genres.json';
import XML from '../data/tsf.xml' /*with { type: "xml" };*/

import { RadioItem } from '../components/Radio';
import RadioPlayer from '../components/RadioPlayer';

import Navbar from '../components/Navbar';
import Template from '../components/Template'

// import Footer from '../components/Footer';



const Aside = () => (
  <aside className="sidenav">{
    RADIOS.genres.value.map(({radios, ...genre}) =>
      <Link
        state={{genre, radios}}
        key={genre.href}
        to={`/genre/${genre.href}`}>{genre.name} ({radios.length})</Link>
    )
  }</aside>
)

const Container = () => {
  console.log('Render container');
  // const genres = React.useMemo(() => radiosGenres(radios), [radios]);
  return (
    <main>
      <Aside/>
      <Template>
        <Outlet/>
      </Template>
    </main>
  );
}


const Footer = () => {
  return (
    <footer style={{
      display:"flex",
      margin: "4px",
      padding: "4px",
      border: "solid 1px",
    }}>
      <RadioPlayer/>
    </footer>
  );
}



const wsUrl = () => {
  if (process.env.NODE_ENV === 'development') return `ws://localhost:3001/api/radiodns`;
  return `wss://${window.location.hostname}/api/radiodns`;
}


const actionSubscribeRadio = ({ channel }) => ({ action: 'subscribe', channel });

const refineGenreName = (genre) => {
  genre.name = RadioGenres[genre.href] || genre.value;
  return genre;  
}

const refineGenre = (info) => {
  if (info?.genres) info.genres = info.genres.map(refineGenreName);
  return info;
}


export default function Root() {
  // console.log('LOG APP', count.value);
 
  const wsRef = React.useRef();

  React.useEffect(() => {
    console.log('loading app', XML);
    (async() => {
      const response = await fetch(XML);
      const result = await response.text();
      console.log('radiodns rds', parseXmlRadios(result));
      RADIOS.stations.value = parseXmlRadios(result);

      const wsSocket = new WebSocket(wsUrl());
      wsSocket.onopen = () => {
        console.log('wsSocket open');
        wsSocket.send(JSON.stringify(RADIOS.stations.value.map(actionSubscribeRadio)));
      }
      wsSocket.onmessage = ({ data }) => {
        console.log('WS Message', data);
        const { action, channel, payload } = JSON.parse(data);
        console.log('WS Message Action', action, channel, payload);
        switch(action) {
          case 'info':
            RADIOS.stations.value = [...RADIOS.stations.value].map(radio =>
              radio.channel === channel
                ? ({
                    ...radio,
                    info: refineGenre(payload),
                  })
                : radio
            );
            break;
          case 'pi':
            RADIOS.stations.value = [...RADIOS.stations.value].map(radio =>
              radio.channel === channel
                ? ({
                    ...radio,
                    epg: payload,
                  })
                : radio
            );
            break;
          case 'subscribed':
            RADIOS.stations.value = [...RADIOS.stations.value].map(radio =>
              radio.channel === channel
                ? ({
                    channel,
                    name:radio.name,
                    meta:payload,
                  })
                : radio
            );
            break;
          case 'message':
            // console.log('receivemessage', radiosSignal.value);
            RADIOS.stations.value = [...RADIOS.stations.value].map(radio =>
              radio.channel === channel
               ? ({
                   ...radio,
                   data: {
                     ...radio.data,
                     ...payload.body,
                   },
                 })
               : radio
            );
            break;
          default:
            break;
        }

      }

      wsRef.current = wsSocket;

    })();
   
    return () => {
      wsRef.current?.close();
      // RADIOS.stations.value = null;
    }
  }, []);

  return (
    <div id="App">
      <Navbar/>
      <Container/>
      <Footer/>
    </div>
  );
}
