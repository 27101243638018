import React from 'react';
import { Helmet } from 'react-helmet';
import { RadioItem } from '../components/Radio'
import * as RADIOS from '../data/state';


const RadioStations = ({ radios = RADIOS.stations.value }) => (
  <>{
      radios.map(radio =>
        <RadioItem key={radio.channel} { ...radio }/>
      )
  }</>
)

export default function Index() {
  console.log('Index context');
 
  return (
    <>
      <RadioStations/>
      <Helmet>
        <title>RadioDNS Demo</title>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
    </>
  );
}