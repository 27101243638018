
const parseXml = (tag, xml) => xml.querySelector(tag)?.textContent;
const extendRadio = (xml, radio, tag) => {
  const value = parseXml(tag, xml);
  if (value !== undefined) radio[tag] = value;
  return radio;
};

const parseXmlAll = (tags, xml, radio) =>
  tags.reduce(extendRadio.bind(null, xml), radio);

const parseRadioStation = (xmlRadioStation) => {
  const radio = {
    name: parseXml('name', xmlRadioStation),
    proto: parseXml('broadcast_protocol', xmlRadioStation),
  };

  switch(radio.proto) {

    case 'fm': {
      parseXmlAll(['country', 'ecc', 'pi', 'freq'], xmlRadioStation, radio);
      console.log('Radio', radio)
      radio.channel = `${radio.freq}.${radio.pi}.${radio.country || radio.pi[0] + radio.ecc}.${radio.proto}`;
      return radio;
    }

    case 'dab': {
      parseXmlAll(['ecc', 'eid', 'sid', 'scids', 'appty', 'uatype', 'pa'], xmlRadioStation, radio);
      radio.appty_uatype = radio.appty;
      if (radio.uatype) {
        radio.appty_uatype += `-${radio.uatype}`;
        delete radio.uatype;
      }
      delete radio.appty;
      radio.channel = `${radio.scids}.${radio.sid}.${radio.eid}.${radio.ecc}.${radio.proto}`;
      return radio;
    }

    default:
      break;
  }
}


const sortRadios = ({name:a, proto:x}, {name:b, proto:y}) => a === b ? x < y ? -1 : 1 : a < b ? -1 : 1;

export const parseXmlRadios = xmlString => {
  console.log('parseXmlRadios', xmlString);
  const parser = new DOMParser();
  const xml = parser.parseFromString(xmlString, "application/xml");
  const radios = [];
  for (let radioXml of xml.querySelectorAll('radio_station')) {
    const radio = parseRadioStation(radioXml);
    if (radio) radios.push(radio);
  }
  return radios.sort(sortRadios);
}
