import { signal, computed } from "@preact/signals-react";
import RadioGenres from '../data/genres.json';

console.log('RadioGenres***', RadioGenres);

export const stations = signal([]);


const sortRadiosGenres = ({ name:a } , { name:b } ) => a < b ? -1 : 1;

const reduceRadioGenres = (genres, radio) => {
  // console.log("reduceRadioGenres", genres, radio);
  if (radio?.info?.genres)
    for (let genre of radio.info.genres) {
      if (genre.href in genres) genres[genre.href].radios.push(radio);
      else genres[genre.href] = {
        ...genre,
        radios:[radio],
      };
    }
  return genres;
}

export const genres = computed(() =>
  Object.values(stations.value.reduce(reduceRadioGenres, {})).sort(sortRadiosGenres)
);

//   const genreRadios = radios.filter(({info :{ genres = [] } = {} }) => genres.some(genre => genre.href === genreId)).sort();

const hasGenre = (genreId, { href }) => genreId === href;
const radioByGenre = (genreId, { info :{ genres = [] } = {} }) =>
  genres.some(hasGenre.bind(null, genreId));


const hasGenreId = (genreId, {  href }) => genreId === href;
 
export const genre = (genreId) => {
  console.log('Genre computed', genreId, genres.value);
  return genres.value.find(hasGenreId.bind(null, genreId));
}

const findStation = (channel, radio) => channel === radio.channel;

export const station = (channel =>
  computed(() =>
    stations.value.find(findStation.bind(null, channel))
  )?.value
);


const filterRadioWithStream = ({ info: { streams = [] } = {} }) => streams?.length;

export const streams = computed(() =>
  stations.value.filter(filterRadioWithStream)
);

export const player = {
  streams,
  // index: computed(() => streams.value?.[0]),
  // next: ()
}