import './AudioControls.css';

const AudioControls = ({
  isPlaying,
  onPlayPauseClick,
  onPrevClick,
  onNextClick,
}) =>  (
  <div className="audio-controls">
    {onPrevClick && <button
      type="button"
      className="prev"
      aria-label="Previous"
      onClick={onPrevClick}
    >
      {/*<Prev />*/}
    prev
    </button>}
    {isPlaying ? (
      <button
        type="button"
        className="pause"
        onClick={() => onPlayPauseClick(false)}
        aria-label="Pause"
      >
        {/*<Pause />*/}
      pause
      </button>
    ) : (
      <button
        type="button"
        className="play"
        onClick={() => onPlayPauseClick(true)}
        aria-label="Play"
      >
        {/*<Play />*/}
      play
      </button>
    )}
    {onNextClick && 
    <button
      type="button"
      className="next"
      aria-label="Next"
      onClick={onNextClick}
    >
      {/*<Next />*/}
    next
    </button>
  }
  </div>
);

export default AudioControls;