export default function Template({ children }) {
  return (
    <section
      style={{
        display: "flex",
        flexDirection: "column",
        flex:"auto",
        overflow: "auto",
        width: "100%",
      }}
    >{children}</section>
  );
}