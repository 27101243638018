import React from 'react';
import { Helmet } from "react-helmet";
import { Link, useLocation, useOutletContext, useParams } from 'react-router-dom'
import * as RADIOS from '../data/state';
import { Image, httpsImage } from './Image';


const randomColor = () => Math.random().toString(16).substr(-6);


export const RadioItem = ({ path, ...radio }) => {
  const {
    channel,
    name,
    meta: { vis : { stomp, http } = {}, epg, tag } = {},
    data: {
      image,
      text,
    } = {},
    info: {
      images = [],
      streams = [],
    } = {},
  } = radio;
  // console.log('RadioItem', channel, stomp, http);
  // console.log('radio MediaMetadata image', image);
  const color = React.useMemo(() => randomColor(), []);
  const logo = images[0];//.find(({height, width}) => height === 128 || width === 128);
  return (
    <>
      <article id={channel} style={{ backgroundColor: `${'#'+ color}`}}>
        <header style={{display:"flex", alignItems:"center"}}>
          {logo?.url && <Image src={logo.url} style={{padding:"4px", height:"32px"}}/>}
          <Link state={radio} to={`/radio/${channel}`}><h3>{streams?.length ? '📻' : ''}{name}</h3></Link>
        </header>
        <section style={{display:"flex", alignItems:"center"}}>
          {image && <Image src={image} style={{height:"50px"}}/>}
         
          {text && <div>{text}</div>}

        </section>
      </article>
      {path &&
        <Helmet>
          <title>RadioDNS - {text ? name + " - " + text : name}</title>
          {logo?.url &&
            <link rel="icon" href={httpsImage(logo.url)} />
          }
      </Helmet>}
    </>
  );
}

export const MemoRadioItem = React.memo(RadioItem);


const RadioProgramme = ({
  longName,
  shortName,
  description,
  link,
  images: [image] = [],
  shortid,
  time : {
    actualduration,
    acutaltime,
    duration,
    time,
  } = {}
}) => {
  console.log('RadioProgramme', longName, shortid)
  return (
    <article style={{
      margin: "4px",
      padding:"4px",
      border: "solid",
    }}>
      <header>
        <time
          style={{
            float: "left",
            color: "grey",
            fontStyle: "italic",
            fontSize: "small",
            padding: "2px 6px 0 0",
            width: "100px",
            textAlign: "center",
          }}
          className="bg-red-100"
          dateTime={time}>{new Date(time).toLocaleTimeString()}
        </time>
        {link?.uri ? <h4 style={{display:"flex"}}>
          <a rel="noopener noreferrer" target="_blank" href={link.uri}>{longName}</a>
        </h4> : null }
      </header>
      <section>
        {image?.url &&
          <Image src={image.url} style={{float: "left", width:"100px"}}/>
        }
        <p>{description}</p>
        {/*<p></p>*/}
      </section>
    </article>
  );
}

export default function Radio() {
  const { radioId } = useParams();
  const radio = RADIOS.station(radioId);
  // const { state } = useLocation();
  // console.log('Radio location', state, radioId);
  if (radio) {
    const epgScopeStartTime = radio?.epg?.scope?.starttime;
    return (
      <>
        <header style={{position:"sticky", top:0, backgroundColor: "white"}}>
          <RadioItem path={true} { ...radio }/>
          {epgScopeStartTime && <div>
            <time time={epgScopeStartTime}>{
              new Date(epgScopeStartTime).toLocaleDateString()
            }</time>
          </div>
        }
        </header>
        {radio?.epg?.programmes.map(programme => (
          <RadioProgramme key={programme.id} { ...programme }/>
        ))}
        </>

    );
  }
  // return <RadioFromContext radioId={radioId}/>
  // return (
  //   <div>Radio: {radioId}</div>
  // );
}